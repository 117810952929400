import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export interface CartItemState {
    isModalOpen: boolean,
    itemId: number | null,
    numItems: number,
    editCartIndex: number,
    validationErrors: any,
    extras: Array<CartItemExtra>,
    comment: string,
}

export interface CartItemExtra {
    itemId: number,
    headerId: number,
    quantity: number,
    menuItemDetachedId?: number
}

const initialState: CartItemState = {
    isModalOpen: false,
    itemId: null,
    numItems: 1,
    editCartIndex: -1,
    validationErrors: [],
    extras: [],
    comment: "",
};

const cartItemSlice = createSlice({
    name: "cartItem",
    initialState,
    reducers: {
        setEditCartIndex(state, action) {
            state.editCartIndex = action.payload;
        },
        setIsModalOpen(state, action) {
            state.isModalOpen = action.payload;
        },
        setNumItems(state, action) {
            state.numItems = action.payload;
        },
        setMenuItemId(state, action) {
            state.itemId = action.payload;
        },
        setExtras(state, action: { payload: Array<CartItemExtra>, type: string }) {
            state.extras = action.payload;
        },
        setComment(state, action) {
            state.comment = action.payload;
        },
        setInitialState() {
            return initialState;
        },
        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },
    },
});

export const {
    setComment,
    setExtras,
    setNumItems,
    setValidationErrors
} = cartItemSlice.actions;

export default cartItemSlice.reducer;

export const openNewCartItemModal = (itemId) => (dispatch) => {
    dispatch(cartItemSlice.actions.setMenuItemId(itemId));
    dispatch(cartItemSlice.actions.setIsModalOpen(true));
};
export const openEditCartItemModal = (editCartIndex) => (dispatch, getState: () => RootState) => {
    const cartItems = getState().cart.items;
    if (!(editCartIndex >= 0 && editCartIndex < cartItems.length)) {
        return;
    }
    const item = cartItems[editCartIndex];
    dispatch(cartItemSlice.actions.setEditCartIndex(editCartIndex));
    dispatch(cartItemSlice.actions.setMenuItemId(item.menuItemId));
    dispatch(cartItemSlice.actions.setExtras(item.extras));
    dispatch(cartItemSlice.actions.setComment(item.comment));
    dispatch(cartItemSlice.actions.setNumItems(item.numItems));
    dispatch(cartItemSlice.actions.setIsModalOpen(true));
};

export const closeModal = () => (dispatch) => {
    dispatch(cartItemSlice.actions.setInitialState());
};
