import { round } from "mathjs";
import { createSlice } from "@reduxjs/toolkit";
import { getDefaultValueForMethod, getInitialState, saveFormToCookie } from "./checkout-slice.helper";
import moment from "moment";
import axios from "axios";
import { formatDate, formatDateTime, formatTime, isSameDateTime } from "../utils/date-utils";
import {getVenueActivePaymentMethods} from "../menu/utils/currentVenueHelper";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: getInitialState(),
  reducers: {
    CREATE_TRANSACTION(state) {
      state.creating = true;
      state.errors = [];
    },
    CREATE_TRANSACTION_CANCEL(state) {
      state.creating = false;
      state.errors = [];
    },
    CREATE_TRANSACTION_SUCCESS(state) {
      state.creating = false;
      state.errors = [];
    },
    CREATE_TRANSACTION_FAILED(state, action) {
      state.creating = false;
      state.errors = action.payload;
    },
    FORM_INCR_TIP_PERCENT(state, action) {
      state.form.tipPercent = round(state.form.tipPercent + action.payload, 2);
      saveFormToCookie(state.form);
    },
    setActivePaymentMethods(state, action) {
      const { swish, card, test, nets, invoice } = action.payload;
      state.paymentMethods.swish = swish;
      state.paymentMethods.card = card;
      state.paymentMethods.test = test;
      state.paymentMethods.nets = nets;
      state.paymentMethods.invoice = invoice;
    },
    SET_FORM_FIELD(state, action) {
      const { field, value } = action.payload;
      if (field in state.form) {
        state.form[field] = value;
      } else {
        throw new Error("unknown form field: " + field);
      }
      saveFormToCookie(state.form);
    },
    setDeliveryMethods(state, action) {
      const { room, table, takeAway, carryOut } = action.payload;
      state.deliveryMethods.room = room;
      state.deliveryMethods.table = table;
      state.deliveryMethods.takeAway = takeAway;
      state.deliveryMethods.carryOut = carryOut;
    },
    setDeliveryWhen(state, action) {
      const { deliveryStart, deliveryEnd } = action.payload;
      if (deliveryStart === "") {
        state.form.deliveryStart = "";
        state.form.deliveryEnd = "";
      } else {
        state.form.deliveryDate = moment(deliveryStart).format("YYYY-MM-DD");
        state.form.deliveryStart = deliveryStart;
        state.form.deliveryEnd = deliveryEnd;
      }
      saveFormToCookie(state.form);
    },
    setDateAndTimeSlots(state, action) {
      const { dateSlots, timeSlots } = action.payload;
      state.dateSlots = dateSlots;
      state.timeSlots = timeSlots;
    },
    setPromoCode(state, action) {
      const { promoCode, isValid } = action.payload;
      state.promoCode = promoCode;
      state.promoCodeIsValid = isValid;
    },
    setCustomField(state, action) {
      const { customFieldId, value } = action.payload;
      state.form.customFields[customFieldId] = value;
      saveFormToCookie(state.form);
    },
  },
});

export const {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_CANCEL,
  CREATE_TRANSACTION_FAILED,
  setDeliveryWhen,
  setCustomField,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;

// -------------- thunks -------------

export const setFormField = (field, value) => (dispatch, getState) => {
  dispatch(checkoutSlice.actions.SET_FORM_FIELD({ field, value }));

  if (field === "deliveryDate") {
    const changeDateOfSelectedDeliveryTime = (newDate) => {
      const form = getState().checkout.form;
      if (form.deliveryStart !== "" && form.deliveryEnd) {
        const deliveryStart = formatDate(newDate) + " " + formatTime(form.deliveryStart);
        const deliveryEnd = formatDate(newDate) + " " + formatTime(form.deliveryEnd);
        dispatch(checkoutSlice.actions.setDeliveryWhen({ deliveryStart, deliveryEnd }));
      }
    };
    changeDateOfSelectedDeliveryTime(value);
    dispatch(loadDateAndTimeSlots());
  }

  if (field === "venueArea") {
    // reset venue table value
    dispatch(checkoutSlice.actions.SET_FORM_FIELD({ field: 'venueTable', value: '' }));
  }

  if (field === "deliveryMethod") {
    dispatch(loadDateAndTimeSlots());
  }
};

/**
 * makes sure that the right date and time slots are loaded.
 * @returns
 */
const loadDateAndTimeSlots = () => async (dispatch, getState) => {
  const venue = getState().venue.current;
  const { deliveryMethod, deliveryDate, deliveryStart, deliveryEnd } = getState().checkout.form;

  let dateSlots = [];
  let timeSlots = [];
  try {
    switch (deliveryMethod) {
      case "room":
      case "table":
        // do nothing, currently only support to order now
        break;
      case "takeAway": {
        dateSlots = Object.keys(venue.takeAwayOpenByDate);


        if (deliveryDate !== '') {
          const { data: openingHours } = await axios.get(`/api/2/opening-hour/by-date?venueId=${venue.id}&deliveryType=takeAway&deliveryStartVenueTime=${deliveryDate}`);
          const now = moment();
          for (let slot of openingHours) {
            const loopStart = moment(deliveryDate + ` ${slot.startTime}`);
            const loopEnd = moment(deliveryDate + ` ${slot.endTime}`);
            for (let currTime = loopStart; currTime <= loopEnd; currTime = currTime.clone().add(15, "minutes")) {
              if (currTime.isBefore(now)) {
                continue;
              }
              timeSlots.push({ startTime: formatDateTime(currTime), endTime: formatDateTime(currTime) });
            }
          }
        }
        break;
      }
      case "carryOut": {
        dateSlots = Object.keys(venue.carryOutSlots);
        let tmpSlots = Array.isArray(venue.carryOutSlots[deliveryDate]) ? venue.carryOutSlots[deliveryDate] : [];
        for (let slot of tmpSlots) {
          const startTime = formatDateTime(deliveryDate + " " + slot.startTime);
          const endTime = formatDateTime(deliveryDate + " " + slot.endTime);
          timeSlots.push({ startTime, endTime });
        }
        break;
      }
      default:
        console.warn("not able to set date and time slots for delivery method: " + deliveryMethod);
    }
  } catch (error) {
    console.error("Problems delivery time picker", error);
  }

  if (dateSlots.length > 0) {
    dateSlots = [...[""], ...dateSlots];
  }
  if (timeSlots.length > 0) {
    timeSlots = [...[{ startTime: "", endTime: "" }], ...timeSlots];
  }

  dispatch(checkoutSlice.actions.setDateAndTimeSlots({ dateSlots, timeSlots }));

  // make sure values of the form have a corresponding value in the date and time options
  const isDeliveryTimeSet = !(deliveryStart === "" && deliveryEnd === "");
  if (isDeliveryTimeSet) {
    const isTimeValid =
      timeSlots.findIndex(
        (slot) => isSameDateTime(slot.startTime, deliveryStart) && isSameDateTime(slot.endTime, deliveryEnd)
      ) > -1;
    if (!isTimeValid) {
      dispatch(checkoutSlice.actions.setDeliveryWhen({ deliveryStart: "", deliveryEnd: "" }));
    }
  }
};

export const initCheckout = () => (dispatch, getState) => {
  const venue = getState().venue.current;

  // load options
  dispatch(
    checkoutSlice.actions.setActivePaymentMethods({
      swish: venue.paymentSwishActive,
      card: venue.paymentCardActive,
      test: venue.paymentTestActive,
      nets: venue.netsPaymentProviderActive,
      invoice: venue.invoicePaymentsActive,
    })
  );

  dispatch(
    checkoutSlice.actions.setDeliveryMethods({
      room: venue.roomDeliveryActive,
      table: venue.tableDeliveryActive,
      takeAway: venue.takeAwayActive,
      carryOut: venue.carryOutActive,
    })
  );

  const form = getState().checkout.form;
  const { deliveryMethods } = getState().checkout;

  const deliveryMethodValid = deliveryMethods[form.deliveryMethod] === true;
  if (!deliveryMethodValid) {
    dispatch(setFormField("deliveryMethod", getDefaultValueForMethod(deliveryMethods)));
  }
  
  const activePaymentMethods = getVenueActivePaymentMethods(venue);
  if( activePaymentMethods.length === 1 ){
    dispatch(setFormField("paymentMethod", activePaymentMethods[0].value));
  }
  else{
    const paymentMethodValid = activePaymentMethods.find( method => method.value === form.paymentMethod ) !== undefined;
    if (!paymentMethodValid) {
      dispatch(setFormField("paymentMethod", activePaymentMethods[0].value));
    }
  }

  if (form.promoCode !== "") {
    dispatch(validatePromoCode(form.promoCode));
  }

  dispatch(loadDateAndTimeSlots());
};

export const resetPromoCode = () => async (dispatch) => {
  dispatch(checkoutSlice.actions.setPromoCode({ promoCode: null, isValid: null }));
  dispatch(setFormField("promoCode", ""));
};

export const validatePromoCode = (promoCode) => async (dispatch, getState) => {
  if (promoCode === "") {
    dispatch(resetPromoCode());
    return;
  }

  const venue = getState().venue.current;
  try {
    const { data } = await axios.get("/api/2/promo-code/validate", {
      params: {
        venueId: venue.id,
        code: promoCode,
      },
    });
    dispatch(checkoutSlice.actions.setPromoCode({ promoCode: data, isValid: true }));
  } catch (error) {
    dispatch(checkoutSlice.actions.setPromoCode({ promoCode: null, isValid: false }));
  }
  dispatch(setFormField("promoCode", promoCode));
};
