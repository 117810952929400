import {CurrentVenueModel} from "../../types/store/venue/CurrentVenueModel";

export const getVenueActivePaymentMethods = (venue: CurrentVenueModel) => {
    return [
        venue.paymentMethods.swedbankV3,
        venue.paymentMethods.swedbankCard,
        venue.paymentMethods.swedbankSwish,
        venue.paymentMethods.swish,
        venue.paymentMethods.invoice,
        venue.paymentMethods.test
    ].filter(method => method);
}